
// import { Fragment, useState } from 'react'
import React from "react";
import Questionary from "./Questionary";


export default function LayoutComponent() {

  return (
    <div className="bg-white">
      <div>
        {/* max-w-7xl */}
        <main className="mx-auto  px-4 sm:px-6 lg:px-8">
          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">

              {/* Product grid */}
              <div className="lg:col-span-3">
                <Questionary />
              </div>
              {/* Filters */}
              <div className="lg:col-span-1">
                <h2> Right panel</h2>
              </div>


            </div>
          </section>
        </main>
      </div>
    </div>
  )
}
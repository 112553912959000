import React from "react";

import { Model, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

//Add a custom 'popupdescription' property to questions of all types and to pages
Serializer.addProperty("question", "popupdescription:text");
Serializer.addProperty("page", "popupdescription:text");


function SurveyComponent(data) {

  const survey = new Model(data.jsonDt);
  survey.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));
  });

  survey.onGetQuestionTitleActions.add((_, opt) => {
    opt.titleActions = [
      {
        title: 'More',
        innerCss: 'btn-more-info',
        action: () => {
          console.log('Custom question information', opt.question)
          console.log('Custom question information', opt.question.popupdescription)
        },
      },
    ];
  });



  console.log('Survey', survey)
  return (<Survey model={survey} />);

}

export default SurveyComponent;
export const json = {
  "title": "Assessment name",
  "description": "Test description",
  "logoPosition": "right",
  "pages": [
    {
      "name": "Background question page title",
      "elements": [
        {
          "type": "panel",
          "name": "patient-information",
          "elements": [
            {
              "type": "text",
              "name": "question1",
              "description": "test description",
              "popupdescription": "custom description"
            }
          ]
        },
        {
          "type": "text",
          "name": "question2"
        }
      ],
      "title": "Background Questions"
    },
    {
      "name": "Health history",
      "elements": [
        {
          "type": "panel",
          "name": "health-history",
          "elements": [
            {
              "type": "boolean",
              "name": "high-cholesterol",
              "title": "High cholesterol?"
            },
            {
              "type": "comment",
              "name": "other-health-conditions",
              "title": "Do you have other health conditions?",
              "maxLength": 300
            }
          ]
        },
        {
          "type": "text",
          "name": "question3"
        },
        {
          "type": "text",
          "name": "question4"
        }
      ],
      "title": "Breach History",
      "description": "Test description"
    },
    {
      "name": "Social history",
      "elements": [
        {
          "type": "panel",
          "name": "social-history",
          "elements": [
            {
              "type": "panel",
              "name": "smoking",
              "elements": [
                {
                  "type": "boolean",
                  "name": "vape",
                  "title": "Do you vape (e-cigarettes)?"
                }
              ]
            },
            {
              "name": "alcohol-use-history",
              "type": "panel",
              "elements": [
                {
                  "name": "alcohol",
                  "type": "boolean",
                  "title": "Do you drink alcohol?"
                },
                {
                  "name": "drinks-per-week",
                  "type": "text",
                  "title": "How many drinks per week?",
                  "visibleIf": "{alcohol} = true"
                }
              ],
              "startWithNewLine": false
            }
          ]
        }
      ],
      "title": "Security history",
      "description": "Test description"
    },
    {
      "name": "Surgical history / recent hospitalizations",
      "elements": [
        {
          "name": "surgery-description",
          "type": "comment",
          "title": "Date and type of surgery / procedure"
        }
      ],
      "title": "Confirm Responses"
    },
    {
      "name": "Family history",
      "elements": [
        {
          "name": "family-history",
          "type": "matrixdynamic",
          "columns": [
            {
              "name": "relation",
              "title": "Relation"
            },
            {
              "name": "health-conditions",
              "title": "Health conditions"
            },
            {
              "name": "cancer-history",
              "title": "Family history of cancer"
            }
          ],
          "cellType": "text",
          "titleLocation": "hidden"
        }
      ],
      "title": "Results"
    }
  ],
  "showQuestionNumbers": "off",
  "questionErrorLocation": "bottom",
  "showProgressBar": "top",
  "showTOC": true,
  "completeText": "Submit",
  "showPreviewBeforeComplete": "showAllQuestions",
  "widthMode": "static",
  "width": "1200px"
}
import React from "react";
// import { useState, useEffect } from 'react'

import "survey-core/defaultV2.min.css";
// import { fetchAssessment } from '../../../functions/assessments'
import SurveyComponent from "./SurveyComponent";
import { json } from '../json'

/**
 * 
 * @returns | JSON object
 */
function Questionary() {
  // const [dt, setDt] = useState(json)

  /**
   * Verify URL param has id
   * Fetch data assessment json based on id
   */
  // useEffect(() => {
  //   const result = fetchAssessment('123456');
  //   result.then(function (res) {
  //     setDt(res)
  //   });
  // }, []);




  return (
    <SurveyComponent jsonDt={json} />);
}

export default Questionary;
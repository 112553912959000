import React from "react";
import './App.css';
// import SurveyComponent from "./SurveyComponent";
// import Questionary from "./components/Questionary";
import Layout from "./components/Layout";

function App() {

  return (
    <div className="App">
      {/* <Questionary /> */}
      <Layout />
    </div>
  );
}

export default App;
